import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Card, CardContent, Container, Hidden, Typography } from "@material-ui/core";
import { LoginJWT } from "../../components/authentication/login";
import Logo from "../../components/Logo";
import gtm from "../../lib/gtm";

const Login = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              {/* Mobile */}
              <Hidden smUp>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    mb: 3,
                    textAlign: "center",
                  }}
                >
                  <Logo style={{ margin: "auto", width: "50%" }} />
                  <Typography color="textSecondary" gutterBottom variant="body2" sx={{ mt: 3, mb: 1 }}>
                    FARMSPLAN LIVE ADMIN
                  </Typography>
                  <Typography color="textPrimary" variant="h4">
                    로그인
                  </Typography>
                </Box>
              </Hidden>

              {/* Desktop */}
              <Hidden smDown>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 3,
                  }}
                >
                  <div>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                      FARMSPLAN LIVE ADMIN
                    </Typography>
                    <Typography color="textPrimary" variant="h4">
                      로그인
                    </Typography>
                  </div>

                  <Box
                    sx={{
                      height: 48,
                      "& > img": {
                        maxHeight: "100%",
                        width: "auto",
                      },
                    }}
                  >
                    <Logo />
                  </Box>
                </Box>
              </Hidden>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <LoginJWT />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
