import { useTheme } from "@material-ui/core";

const Logo = (props) => {
  const { palette } = useTheme();

  if (palette.mode === "dark") {
    return <img src="/static/logo.png" style={props.style} alt="LOGO" />;
  } else {
    return <img src="/static/logo-color.png" style={props.style} alt="LOGO" />;
  }
};

export default Logo;
