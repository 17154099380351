import { useRoutes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core";
import "./i18n";
import GlobalStyles from "./components/GlobalStyles";
import RTL from "./components/RTL";
import SettingsDrawer from "./components/SettingsDrawer";
import SplashScreen from "./components/SplashScreen";
import { Helmet } from "react-helmet-async";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";
import { buildTheme } from "./theme";
import "./App.css";
import AdapterMoment from "@material-ui/lab/AdapterMoment";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: '20a1f1a0-c7fe-4ccb-9e72-6ec082abd73d',
  clientToken: 'pubd9c3ba03a16900f292d8b110b1538859',
  site: 'datadoghq.com',
  service:'live-admin',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();

const App = () => {

  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  const theme = buildTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>FARMSPLAN LIVE ADMIN</title>
      </Helmet>
      <RTL direction={settings.direction}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider dense maxSnack={3}>
            <GlobalStyles />
            {/* <SettingsDrawer /> */}
            {auth.isInitialized ? content : <SplashScreen />}
          </SnackbarProvider>
        </LocalizationProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
