import { DialogTitle, Dialog, List, ListItem, DialogContent, ListItemText, TextField } from "@material-ui/core";
import { Box } from "@material-ui/system";
import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";

export default function FarmListModal({ open, onClose }) {
  const { farmList, updateFarm, activeFarm } = useAuth();
  const [search, setSearch] = useState("");

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>농장 선택</DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Box sx={{ p: 1 }}>
            <TextField
              fullWidth
              size="small"
              label="검색"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              autoFocus
            />
          </Box>
          <List>
            {farmList
              .filter((item) => item.name.includes(search))
              .map((item) => (
                <ListItem
                  key={item.id}
                  button
                  onClick={() => {
                    updateFarm(item);
                    onClose();
                  }}
                  selected={activeFarm.id === item.id}
                >
                  <ListItemText primary={item.name} secondary={item.address} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
