import { createContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { API } from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
const SOCKET_URL =
  process.env.NODE_ENV === "production"
    ? "wss://api.farmsplan.com/ws/notify-tasks"
    : "wss://api-staging.farmsplan.com/ws/notify-tasks";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: {},
  farmList: [],
  activeFarm: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, farmList, activeFarm } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      farmList,
      activeFarm,
    };
  },

  LOGIN: (state, action) => {
    const { user, farmList, activeFarm } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      user,
      farmList,
      activeFarm,
    };
  },

  UPDATEFARM: (state, action) => {
    const { activeFarm } = action.payload;

    return {
      ...state,
      activeFarm,
    };
  },

  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: {},
    farmList: [],
    activeFarm: {},
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [wsChannel, setWsChannel] = useState(null);
  const [socketUrl, setSocketUrl] = useState(null);
  const [socketLog, setSocketLog] = useState([]);

  const { readyState } = useWebSocket(socketUrl, {
    onOpen: (data) => console.log("socket open", data),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => false,
    onMessage: (data) => {
      console.log(data.data);
      const ws = JSON.parse(data.data);
      if (ws.event === "init") {
        setWsChannel(ws.channel);
      } else {
        const x = socketLog;
        x.push(ws);
        setSocketLog(x);
      }
    },
  });

  useEffect(() => {
    if (location.pathname.includes("/report")) {
      setSocketUrl(SOCKET_URL);
    } else {
      setSocketUrl(null);
    }
  }, [location]);

  useEffect(() => {
    initialize();
  }, []);

  const setSession = (token) => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.clear();
    }
  };

  const initialize = async () => {
    const token = localStorage.getItem("token");
    const localFarmId = localStorage.getItem("localFarmId");
    if (token) {
      const { user, farmList } = await getUserData(token);

      console.log(user);
      if (user.email === "tracement@aidkr.com") {
        logout();
        return;
      }

      setSession(token);

      if (farmList.length < 1) {
        alert("권한이 있는 농장이 없습니다");
        return;
      }

      let activeFarm = farmList[0];

      const localFarm = farmList.filter((item) => item.id === parseInt(localFarmId));

      if (localFarm.length > 0) {
        activeFarm = localFarm[0];
      }

      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: true,
          user,
          farmList,
          activeFarm,
        },
      });
    } else {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: {},
          farmList: [],
          activeFarm: {},
        },
      });
    }
  };

  const login = async (email, password) => {
    const loginData = await axios({
      url: API + "/auth/users/login",
      method: "POST",
      data: {
        email,
        password,
      },
    });

    const { token } = loginData.data;

    const { user, farmList } = await getUserData(token);

    if (farmList.length < 1) {
      alert("권한이 있는 농장이 없습니다");
      return;
    }

    setSession(token);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
        farmList,
        activeFarm: farmList[0],
      },
    });
  };

  const getUserData = async (token) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      };

      const permission = await axios({
        url: API + "/backoffice/users/me",
        headers,
      });
      // console.log(permission.data);

      const farms = await axios({
        url: API + "/backoffice/farms",
        headers,
        params: {
          limit: 1000,
        },
      });

      // console.log("farms", farms);

      return {
        user: permission.data,
        farmList: farms.data,
      };
    } catch (e) {
      console.log(e);
      localStorage.clear();
      window.location = "/";
    }
  };

  const logout = async () => {
    setSession(null);
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
  };

  const updateFarm = (activeFarm) => {
    localStorage.setItem("localFarmId", activeFarm.id);

    dispatch({
      type: "UPDATEFARM",
      payload: {
        activeFarm,
      },
    });
  };

  const connectSocket = (x) => {
    // x -> true: 소켓 연결 / false: 연결 해제

    setSocketUrl(x ? SOCKET_URL : null);
    if (!x) {
      setWsChannel(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        updateFarm,
        connectSocket,
        readyState,
        wsChannel,
        socketLog,
        setSocketLog,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
