import { Suspense, lazy } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";
import Handon from "./pages/handon";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(lazy(() => import("./pages/authentication/PasswordRecovery")));
const PasswordReset = Loadable(lazy(() => import("./pages/authentication/PasswordReset")));
const Register = Loadable(lazy(() => import("./pages/authentication/Register")));
const VerifyCode = Loadable(lazy(() => import("./pages/authentication/VerifyCode")));

const TokenRedirected = Loadable(lazy(() => import("./pages/TokenRedirected")));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import("./pages/AuthorizationRequired")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// FARMSPLAN PAGES

// const Login = Loadable(lazy(() => import("./pages/auth/Login")));
const Home = Loadable(lazy(() => import("./pages/Home")));
const Bio = Loadable(lazy(() => import("./pages/bio/Bio")));
const CamAi = Loadable(lazy(() => import("./pages/camAi/CamAi")));
const Stream = Loadable(lazy(() => import("./pages/stream/Stream")));

const OneMinute = Loadable(lazy(() => import("./pages/oneminute/OneMinute")));

const ReportCheck = Loadable(lazy(() => import("./pages/liveReport/ReportCheck")));
const ReportDone = Loadable(lazy(() => import("./pages/liveReport/ReportDone")));
const ReportAll = Loadable(lazy(() => import("./pages/liveReport/ReportAll")));

const Builder = Loadable(lazy(() => import("./pages/builder/Builder")));
const Clean = Loadable(lazy(() => import("./pages/camera/Clean")));
const Connection = Loadable(lazy(() => import("./pages/camera/Connection")));

const Equipment = Loadable(lazy(() => import("./pages/ictManager/equipment/Equipment")));
const Category = Loadable(lazy(() => import("./pages/ictManager/category/Category")));
const Location = Loadable(lazy(() => import("./pages/ictManager/location/Location")));

const RealtimeAi = Loadable(lazy(() => import("./pages/realtime/Ai")));

// INFRA
const Rooms = Loadable(lazy(() => import("./pages/infra/Rooms")));
const Cameras = Loadable(lazy(() => import("./pages/infra/Cameras")));
const Recorders = Loadable(lazy(() => import("./pages/infra/Recorders")));

const Performance = Loadable(lazy(() => import("./pages/dev/Performance")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: (
          <AuthGuard>
            <PasswordReset />
          </AuthGuard>
        ),
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
      {
        path: "redirected",
        element: <TokenRedirected />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "bio",
        element: <Bio />,
      },
      {
        path: "cam-ai",
        element: <CamAi />,
      },
      {
        path: "builder",
        element: <Builder />,
      },
      {
        path: "handon",
        element: <Handon />,
      },
      {
        path: "stream",
        element: <Stream />,
      },
      {
        path: "oneminute",
        element: <OneMinute />,
      },
      {
        path: "equipment",
        element: <Equipment />,
      },
      {
        path: "category",
        element: <Category />,
      },
      {
        path: "location",
        element: <Location />,
      },
      {
        path: "realtime-ai",
        element: <RealtimeAi />,
      },
      {
        path: "report",
        children: [
          {
            path: "check",
            element: <ReportCheck />,
          },
          {
            path: "done",
            element: <ReportDone />,
          },
          {
            path: "all",
            element: <ReportAll />,
          },
        ],
      },
      {
        path: "infra",
        children: [
          {
            path: "rooms",
            element: <Rooms />,
          },
          {
            path: "cameras",
            element: <Cameras />,
          },
          {
            path: "recorders",
            element: <Recorders />,
          },
        ],
      },
      {
        path: "camera",
        children: [
          {
            path: "connection",
            element: <Connection />,
          },
          {
            path: "clean",
            element: <Clean />,
          },
        ],
      },
      {
        path: "performance",
        element: <Performance />,
      },
    ],
  },
  {
    path: "error",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
