import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button, Divider, Drawer, Hidden, Typography } from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import {
  PlayLessonTwoTone,
  BiotechTwoTone,
  OndemandVideoTwoTone,
  VideocamTwoTone,
  MemoryTwoTone,
  BuildTwoTone,
  LooksOneTwoTone,
  Videocam,
  CameraOutdoor,
  LibraryAdd,
  AddLocation,
  DeviceHubTwoTone,
  DevicesTwoTone,
  MicrowaveTwoTone,
  SpeedTwoTone,
  CameraOutdoorTwoTone,
  LibraryAddTwoTone,
  AddLocationTwoTone,
} from "@material-ui/icons";

const sections = [
  {
    title: "FARMSPLAN LIVE",
    permission: ["admin", "AI", "FT", "UX", "SI"],
    items: [
      // AI, 축전실 전부 (FT, UX, SI)
      {
        title: "CAM AI",
        path: "/cam-ai",
        icon: <MemoryTwoTone fontSize="small" />,
        permission: ["admin", "AI", "FT", "UX", "SI"],
      },
      // AI, 축전실 전부 (FT, UX, SI)
      // {
      //   title: "실시간 스트리밍",
      //   path: "/stream",
      //   icon: <OndemandVideoTwoTone fontSize="small" />,
      //   permission: ["admin", "AI", "FT", "UX", "SI"],
      // },
      // AI, 축전실 전부 (FT, UX, SI)
      {
        title: "어제의 1분",
        path: "/oneminute",
        icon: <OndemandVideoTwoTone fontSize="small" />,
        permission: ["admin", "AI", "FT", "UX", "SI"],
      },
      {
        title: "한돈팜스",
        path: "/handon",
        icon: <MicrowaveTwoTone fontSize="small" />,
        permission: ["admin"],
      },
      // {
      //   title: "실시간 AI",
      //   path: "/realtime-ai",
      //   icon: <OndemandVideoTwoTone fontSize="small" />,
      //   permission: ["admin", "AI", "FT", "UX", "SI"],
      // },
      // UX, Joy
      {
        title: "라이브 리포트",
        path: "/report",
        icon: <PlayLessonTwoTone fontSize="small" />,
        permission: ["admin", "UX"],
        children: [
          {
            title: "리포트 발행 & 검수",
            path: "/report/check",
          },
          // {
          //   title: "발송 내역",
          //   path: "/report/send",
          // },
          {
            title: "완료 리포트",
            path: "/report/done",
          },
          {
            title: "전체 리포트",
            path: "/report/all",
          },
        ],
      },
    ],
  },
  {
    // UX / SI
    title: "현장 공사",
    permission: ["admin", "UX", "SI"],
    items: [
      {
        title: "공사 관리",
        path: "/builder",
        icon: <BuildTwoTone fontSize="small" />,
        permission: ["admin", "UX", "SI"],
      },
      {
        title: "인프라 관리",
        path: "/infra",
        icon: <DevicesTwoTone fontSize="small" />,
        permission: ["admin", "UX", "SI"],
        children: [
          {
            title: "방",
            path: "/infra/rooms",
          },
          {
            title: "카메라",
            path: "/infra/cameras",
          },
          {
            title: "녹화기",
            path: "/infra/recorders",
          },
        ],
      },
      // UX / SI
      {
        title: "카메라 연결 관리",
        path: "/camera",
        icon: <Videocam fontSize="small" />,
        permission: ["admin", "UX", "SI"],
        children: [
          {
            title: "전체 카메라",
            path: "/camera/all",
          },
          {
            title: "연결 실패 카메라",
            path: "/camera/connection",
          },
          {
            title: "청소 필요 카메라",
            path: "/camera/clean",
          },
        ],
      },
    ],
  },
  {
    // BT - 그룹권한 추가 필요 // BT 3개 파트 중에서 병성감정만 추가하는걸로...
    title: "바이오 분석 센터",
    permission: ["admin", "BT"],
    items: [
      {
        title: "바이오 분석 보고서",
        path: "/bio",
        icon: <BiotechTwoTone fontSize="small" />,
        permission: ["admin", "BT"],
      },
    ],
  },
  {
    // 테스트용
    title: "Performancè Analyzer",
    permission: ["admin", "BT"],
    items: [
      {
        title: "Performancè Analyzer",
        path: "/performance",
        icon: <SpeedTwoTone fontSize="small" />,
        permission: ["admin", "BT"],
      },
    ],
  },
  {
    title: "ICT 매니저",
    permission: ["admin", "UX", "PRODUCT"],
    items: [
      {
        title: "ICT 장비관리",
        path: "/equipment",
        icon: <CameraOutdoorTwoTone fontSize="small" />,
        permission: ["admin", "UX", "PRODUCT"],
      },
      {
        title: "ICT 카테고리",
        path: "/category",
        icon: <LibraryAddTwoTone fontSize="small" />,
        permission: ["admin", "UX", "PRODUCT"],
      },
      {
        title: "ICT 설치장소",
        path: "/location",
        icon: <AddLocationTwoTone fontSize="small" />,
        permission: ["admin", "UX", "PRODUCT"],
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const navigate = useNavigate();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { logout, user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  let menuList = [];

  // console.log("user", user);

  let permission = "guest";

  if (user.is_superuser) {
    permission = "admin";
  } else {
    permission = user.groups;
  }

  const checkPermission = (menu, user) => {
    let temp = false;
    menu.map((x) => {
      if (user.includes(x)) {
        temp = true;
      }
    });
    return temp;
  };

  sections.map((section) => {
    if (checkPermission(section.permission, permission)) {
      let innerTemp = [];
      section.items &&
        section.items.map((inner) => {
          if (checkPermission(inner.permission, permission)) {
            innerTemp.push(inner);
          }
        });

      menuList.push({
        ...section,
        items: innerTemp,
      });
    }
  });

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <RouterLink to="/">
              <img src="/static/logo.png" alt="LOGO" width="70%" style={{ marginTop: "15px" }} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Box sx={{ ml: 0 }}>
              <Typography color="textPrimary" variant="h6">
                {user.username}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user.email}
              </Typography>
            </Box>
            <Button onClick={logout} color="primary" size="small" variant="contained">
              로그아웃
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {menuList.map((section, index) => (
            <NavSection
              key={index}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            도움이 필요하신가요?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            온라인 도움말을 확인해보세요
          </Typography>
          <Button
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => window.open("/static/Online-Guide.pdf", "_blank")}
            variant="contained"
          >
            온라인 도움말
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
