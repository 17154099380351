import { Box } from "@material-ui/core";
import React from "react";

export default function Handon() {
  return (
    <Box sx={{ p: 2, bgcolor: "white" }}>
      <iframe
        src="https://api-staging2.farmsplan.com/pig/handonfarms"
        style={{ height: "calc(100vh - 100px)", width: "100%", border: "none" }}
      ></iframe>
    </Box>
  );
}
