// export const API = "https://api.farmsplan.com";
// export const API = "https://api-staging.farmsplan.com";

export const HEADERS = {
  Authorization: "Bearer " + localStorage.getItem("token"),
};

export const HEADER = {
  Authorization: "Bearer " + localStorage.getItem("token"),
};

const isLocalhost = window.location.origin.includes("localhost");
const isStaging = window.location.origin.includes("admin-dev");

export const API = isStaging || isLocalhost ? "https://api-staging.farmsplan.com" : "https://api.farmsplan.com";
export const PIG_API =
  isStaging || isLocalhost ? "https://api-staging.farmsplan.com/pig" : "https://api.farmsplan.com/pig";

export const BACKOFFICE_CRM_URL = isLocalhost
  ? "http://localhost:3000"
  : isStaging
  ? "https://backoffice-dev.farmsplan.com"
  : "https://backoffice.farmsplan.com";
