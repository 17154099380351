import { Link as RouterLink, useLocation } from "react-router-dom";
import { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Button,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
import ContactsPopover from "./ContactsPopover";
import ContentSearch from "./ContentSearch";
import LanguagePopover from "./LanguagePopover";
import Logo from "../Logo";
import NotificationsPopover from "./NotificationsPopover";
import { KeyboardArrowDown } from "@material-ui/icons";
import useAuth from "../../hooks/useAuth";
import FarmListModal from "./FarmListModal";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { activeFarm } = useAuth();
  const [farmModal, setFarmModal] = useState(false);

  const location = useLocation();

  return (
    <>
      <FarmListModal open={farmModal} onClose={() => setFarmModal(false)} />
      <DashboardNavbarRoot {...other}>
        <Toolbar sx={{ minHeight: 64 }}>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onSidebarMobileOpen}>
              <MenuIcon fontSize="small" />
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <RouterLink to="/">
              <img
                src="/static/logo.png"
                alt="LOGO"
                style={{ height: "40px", marginTop: "5px" }}
              />
            </RouterLink>
          </Hidden>
          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
            }}
          />
          {location.pathname === "/category" ? (
            ""
          ) : (
            <Button
              variant="contained"
              color="success"
              endIcon={<KeyboardArrowDown />}
              onClick={() => setFarmModal(true)}
            >
              {activeFarm.name}
            </Button>
          )}
          {/*<Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box> */}
          {/* <Box sx={{ ml: 1 }}> */}
          {/* <ContactsPopover /> */}
          {/* </Box> */}
          {/* <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}
          {/* <Box sx={{ mr: 1, ml: 2 }}>
          <AccountPopover />
        </Box> */}
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
